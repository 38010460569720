import { FC, useCallback, useRef, useState } from 'react';
import { EditableCellProps } from './GenericInputCell';
import PhoneInput from 'react-phone-number-input';
import CountryCodeSelect from '../../shared/CountryCodeSelect';
import { PhoneNumberInput } from '../../form/action-types/phone-number/PhoneNumberAction';

const PhoneNumberInputCell: FC<EditableCellProps> = (props) => {
  const { cell, onChange, onClose } = props;

  const contentRef = useRef<HTMLDivElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(cell.getValue() ?? '');

  const emitUpdate = useCallback(
    (value: string | null) => {
      if (value !== cell.getValue()) {
        onChange(value);
      }
    },
    [onChange, cell],
  );

  const onBlur = useCallback(() => {
    setHasFocus(false);

    // if focus is not in `contentRef`, timeout to give it time to switch focus
    setTimeout(() => {
      if (!contentRef.current?.contains(document.activeElement)) {
        emitUpdate(phoneNumber);
        onClose();
      }
    }, 10);
  }, [emitUpdate, onClose, phoneNumber]);

  const SelectComponent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any) => (
      <div className="w-28">
        <CountryCodeSelect {...props} menuPosition="left" flagOnly={true} className={`rounded-r-none border-r-0`} onBlur={onBlur} />
      </div>
    ),
    [onBlur],
  );

  return (
    <div ref={contentRef}>
      <PhoneInput
        international
        defaultCountry={'DE'}
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e?.toString() ?? '')}
        countryCallingCodeEditable
        countrySelectComponent={SelectComponent}
        inputComponent={PhoneNumberInput}
        numberInputProps={{ isValid: true, hasFocus, disabled: false, autoFocus: true }}
        onBlur={onBlur}
        onFocus={() => setHasFocus(true)}
        className="flex w-full items-center"
      />
    </div>
  );
};

export default PhoneNumberInputCell;
