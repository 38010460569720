/* eslint-disable @typescript-eslint/no-explicit-any */
import { SortingState, Table } from '@tanstack/react-table';
import { FC, useMemo } from 'react';
import User from '../../models/User';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import NoSortingSvg from '../../assets/images/empty-sorting.svg';
import XIcon from '../shared/icon/XIcon';
import Badge from '../shared/badge/Badge';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { ArrowIcon, ArrowType } from '../shared/icon/ArrowIcon';
import { SortDirection, SortDirectionKeys } from '../../models/TableView';
import { useTranslation } from 'react-i18next';

type Props = {
  columnSorting: SortingState;
  table: Table<User>;
};

const UserPermissionsSortingOverview: FC<Props> = (props) => {
  const { columnSorting, table } = props;
  const { t } = useTranslation(['organisation', 'table-view']);

  const columns = useMemo(() => {
    const cols = [];

    for (const sort of columnSorting) {
      const column = table.getColumn(sort.id);
      if (column) {
        cols.push({ id: column.id, column, value: sort.desc ? ('desc' as const) : ('asc' as const) });
      }
    }

    return cols;
  }, [columnSorting, table]);

  return (
    <div className="min-w-128 max-w-[800px] px-2">
      <div className="flex items-center justify-between">
        <h3 className="text-dpm-18 font-semibold">{t('table-view:global-actions.sort-popover.title')}</h3>
        <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={() => table.resetSorting()} disabled={columns.length === 0}>
          {t('table-view:global-actions.sort-popover.reset')}
        </Button>
      </div>

      {columns.length === 0 ? (
        <div className="text-center">
          <img src={NoSortingSvg} alt={t('table-view:global-actions.sort-popover.empty')} className="mx-auto p-8" />
          <p className="text-gray-2 pt-4">{t('table-view:global-actions.sort-popover.empty')}</p>
        </div>
      ) : (
        <div className="mt-4 max-h-[450px] overflow-y-auto pr-2">
          {columns.map((col) => (
            <div key={col.id} className="mb-4">
              <div className="flex items-center justify-between">
                <div className="text-dpm-14 mb-1 font-medium">{t(`organisation:permissions.member-fields-config.fields.${col.id}` as any)}</div>
                <div className="ml-4 flex items-center justify-end gap-2 pb-1">
                  <XIcon className="h-5 w-5 flex-shrink-0" onClick={() => col.column.clearSorting()} />
                </div>
              </div>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={
                  <div
                    className="flex cursor-pointer items-center gap-2"
                    {...mouseAndKeyboardCallbackProps(() => col.column.toggleSorting(col.value !== 'desc', true))}
                  >
                    <ArrowIcon className="h-4 w-4" type={col.value === 'asc' ? ArrowType.UP : ArrowType.DOWN} />
                    {t(SortDirectionKeys[col.value === 'asc' ? SortDirection.Ascending : SortDirection.Descending])}
                  </div>
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserPermissionsSortingOverview;
