import { FC } from 'react';
import { Cell, flexRender } from '@tanstack/react-table';
import User from '../../../models/User';
import StringUtils from '../../../utils/StringUtils';

type Props = {
  cell: Cell<User, unknown>;
  searchTerm: string;
};

const DefaultCellRenderer: FC<Props> = (props) => {
  const { cell, searchTerm } = props;

  if (searchTerm && cell.column.getCanGlobalFilter()) {
    const value = cell.getValue() as string;
    return <span>{StringUtils.highlightText(value, searchTerm)}</span>;
  }

  return flexRender(cell.column.columnDef.cell, cell.getContext());
};

export default DefaultCellRenderer;
