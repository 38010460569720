/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cell } from '@tanstack/react-table';
import { FC, useCallback, useState } from 'react';
import User from '../../../models/User';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import GenericInputCell from './GenericInputCell';
import { FCWithChildren } from '../../../types/FCWithChildren';
import AddressInputCell from './AddressInputCell';
import PhoneNumberInputCell from './PhoneNumberInputCell';
import DefaultCellRenderer from './DefaultCellRenderer';

const INPUT_TYPE_MAP = {
  firstName: GenericInputCell,
  lastName: GenericInputCell,
  employeeId: GenericInputCell,
  address: AddressInputCell,
  officeLocation: AddressInputCell,
  phoneNumber: PhoneNumberInputCell,
};

type Props = {
  cell: Cell<User, unknown>;
  searchTerm: string;
  onChange: (value: any) => void;
};

const ClickEditCell: FC<Props> = (props) => {
  const { cell, searchTerm, onChange } = props;
  const [editing, setEditing] = useState(false);

  const beginEdit = useCallback(() => {
    setEditing((prev) => !prev);
  }, []);

  const stopEdit = useCallback(() => {
    setEditing(false);
  }, []);

  if (!editing) {
    return (
      <EditableTextWrapper beginEdit={beginEdit}>
        <DefaultCellRenderer cell={cell} searchTerm={searchTerm} />
      </EditableTextWrapper>
    );
  }

  const Component = INPUT_TYPE_MAP[cell.column.id as keyof typeof INPUT_TYPE_MAP] ?? GenericInputCell;

  return (
    <div>
      <Component cell={cell as any} onChange={onChange} onClose={stopEdit} />
    </div>
  );
};

export default ClickEditCell;

const EditableTextWrapper: FCWithChildren<{ beginEdit: () => void }> = (props) => (
  <div {...mouseAndKeyboardCallbackProps(props.beginEdit)} className="hover:bg-gray-5 min-h-8 cursor-pointer rounded-md p-2">
    {props.children}
  </div>
);
