/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback } from 'react';
import { EditableCellProps } from './GenericInputCell';
import AddressInput from '../../shared/form-control/AddressInput';
import AddressLocation from '../../../models/AddressLocation';
import { InputStyle } from '../../shared/form-control/Input';
import User from '../../../models/User';

const AddressInputCell: FC<EditableCellProps<AddressLocation | null>> = (props) => {
  const { cell, onChange, onClose } = props;

  const initialValue = cell.row.original[(cell.column.columnDef.meta as any)?.field as keyof User] as AddressLocation;

  const emitUpdate = useCallback(
    (value: AddressLocation | null) => {
      if (value !== initialValue) {
        onChange(value);
      }
    },
    [initialValue, onChange],
  );

  const onAddressChanged = useCallback(
    (value: AddressLocation | null) => {
      emitUpdate(value);
      onClose();
    },
    [emitUpdate, onClose],
  );

  return (
    <AddressInput
      inputStyle={InputStyle.MINIMAL}
      initialValue={initialValue?.address ?? ''}
      onAddressChanged={onAddressChanged}
      autoFocus
      // timeout to give it time to emit the address for onChange
      onBlur={() => setTimeout(onClose, 200)}
      onClear={() => emitUpdate(null)}
    />
  );
};

export default AddressInputCell;
