import { Cell } from '@tanstack/react-table';
import { FC, KeyboardEvent, useCallback, useState } from 'react';
import User from '../../../models/User';
import { Input, InputStyle } from '../../shared/form-control/Input';

export type EditableCellProps<T = string | null> = {
  cell: Cell<User, T>;
  onChange: (value: T) => void;
  onClose: () => void;
};

const GenericInputCell: FC<EditableCellProps> = (props) => {
  const { cell, onChange, onClose } = props;
  const [value, setValue] = useState((cell.getValue() as string) ?? '');

  const emitUpdate = useCallback(() => {
    if (value !== cell.getValue()) {
      onChange(value);
    }
  }, [cell, onChange, value]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        emitUpdate();
        onClose();
      }
    },
    [emitUpdate, onClose],
  );

  const onBlur = useCallback(() => {
    emitUpdate();
    onClose();
  }, [emitUpdate, onClose]);

  return (
    <Input style={InputStyle.MINIMAL} value={value} onChange={(e) => setValue(e.target.value)} onKeyDown={onKeyDown} onBlur={onBlur} autoFocus />
  );
};

export default GenericInputCell;
