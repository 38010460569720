import { FC, useMemo } from 'react';
import { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { Cell } from '@tanstack/react-table';
import User from '../../../models/User';
import { DefaultMemberFieldKeys } from '../../../models/ClientMemberFields';
import { ClientTagType } from '../../../models/ClientTag';

import TagSelector from '../../tags/TagSelector';
import { useCurrentClient } from '../../../global-state/Clients';
import { useTranslation } from 'react-i18next';

export const ButtonDropdownClassName =
  'hover:!bg-gray-5 focus:!bg-gray-5 rounded-md !border-0 !bg-transparent [&_svg]:opacity-0 [&_svg]:transition-opacity group-hover/row:[&_svg]:opacity-100';

type Props = {
  cell: Cell<User, unknown>;
  onChange: (newId: string) => void;
};

const TAG_TYPE_MAP: Partial<Record<DefaultMemberFieldKeys, ClientTagType>> = {
  groupId: ClientTagType.UserGroup,
  positionId: ClientTagType.UserPosition,
  departmentId: ClientTagType.UserDepartment,
} as const;

const TagDropdownCell: FC<Props> = (props) => {
  const { cell, onChange } = props;
  const { t } = useTranslation('common');

  const currentClient = useCurrentClient((x) => x.value);

  const tagType = useMemo(() => TAG_TYPE_MAP[cell.column.id as DefaultMemberFieldKeys] as ClientTagType, [cell.column.id]);

  const value = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const field = (cell.column.columnDef?.meta as any)?.field as 'groupId' | 'positionId' | 'departmentId';
    const id = cell.row.original.clientCustomData?.[currentClient!.id]?.[field];

    return id ? [id] : undefined;
  }, [cell.column.columnDef?.meta, cell.row.original.clientCustomData, currentClient]);

  const placeholder = useMemo(() => {
    switch (tagType) {
      case ClientTagType.UserGroup:
        return t('tags.select-group-placeholder');
      case ClientTagType.UserPosition:
        return t('tags.select-position-placeholder');
      case ClientTagType.UserDepartment:
        return t('tags.select-department-placeholder');
    }
  }, [t, tagType]);

  return (
    <div>
      <TagSelector
        size={DropdownSize.S}
        type={tagType}
        className={ButtonDropdownClassName}
        onTagsChanged={(o) => o.length > 0 && onChange(o[0].id)}
        selectedTagIds={value}
        isMulti={false}
        canEditTags
        placeholder={placeholder}
        noOptionsText={t('tags.no-tags-placeholder')}
      />
    </div>
  );
};

export default TagDropdownCell;
