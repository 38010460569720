import { Cell } from '@tanstack/react-table';
import User from '../../../models/User';
import { FC, useMemo } from 'react';
import DropdownSelect, { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { ButtonDropdownClassName } from './TagDropdownCell';
import { Roles } from '../../../models/Role';
import usePermissions from '../../../hooks/permissions/usePermissions';
import { useTranslation } from 'react-i18next';

const assignableRoles = Object.values(Roles).filter((x) => x !== Roles.SuperAdmin);

type Props = {
  cell: Cell<User, unknown>;
  onChange: (newRole: Roles) => void;
};

const RoleCell: FC<Props> = (props) => {
  const { cell, onChange } = props;
  const hasPermission = usePermissions();
  const { t } = useTranslation('common');

  const options = useMemo(() => {
    return assignableRoles
      .filter((role) => hasPermission(role))
      .map((role) => ({
        id: role,
        text: t(`roles.${role}`),
        value: role,
      }));
  }, [hasPermission, t]);

  const value = useMemo(() => {
    const cellValue = cell.getValue() as string | null;
    if (!cellValue) return null;

    // Important: we compare against text here, since the table cell value is a string for sorting/filtering purposes
    return options.find((option) => option.text === cellValue) ?? { id: cellValue as Roles, text: cellValue, value: cellValue as Roles };
  }, [cell, options]);

  return (
    <div>
      <DropdownSelect value={value} options={options} onChange={(o) => onChange(o.id)} size={DropdownSize.S} className={ButtonDropdownClassName} />
    </div>
  );
};

export default RoleCell;
